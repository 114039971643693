<template>
  <div class="setting world">
    <div class="title backgroundWhite marB10">
      <h1 class="h1">修改手机号</h1>
    </div>
    <div class="setting_content backgroundWhite">
      <div class="tip gray">
        <p>注：手机号用于登录，找回密码。修改成功后，可以使用新手机号码登录。</p>
      </div>
      <div class="content">
        <div class="box">
          <el-form ref="form" :model="form" label-width="200px" class="form" :rules="phonerules">
            <el-form-item label="当前绑定：" class="w350">
              <div class="iphone">{{iphone}}</div>
            </el-form-item>
            <el-form-item label="更改绑定手机号：">
              <el-input placeholder="请输入手机号" v-model="form.newPhone" class="w350">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code" label="短信验证码：">
              <el-input
                v-model="form.code"
                placeholder="请输入短信验证码"
                @keyup.enter.native="loginByphone('form')"
                auto-complete="off"
                class="w350"
              >
                <template slot="append">
                  <el-button
                    @click="sendMsg"
                    class="sendcode"
                    :class="{'disabled-style':getCodeBtnDisable}"
                    :disabled="getCodeBtnDisable"
                  >{{codeBtnWord}}</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="marT50">
              <el-button type="primary" class="bs_btn_oo w350" @click="modifyMobile('form')">确认修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <dialogImageCode ref="dialogImageCode" @changeCode="sendCheckCode"/>
  </div>
</template>

<script>
import qs from "qs";
import dialogImageCode from "components/login/dialogImageCode";
export default {
  name: "setting",
  components:{dialogImageCode},
  data() {
    return {
      btntxt: "重新发送",
      codeBtnWord: "获取验证码",
      waitTime: 61,
      i: '<i class="el-icon-d-arrow-right"></i>',
      form: {
        newPhone: "",
        iphone: "",
        code: "",
      },
      iphone: "",
      phonerules: {
        newPhone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入收到的验证码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.phone.iphone)) {
        return false;
      }
      return true;
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          if (this.form.newPhone) {
            return false;
          }
          return true;
        }
        return true;
      },
      set() {},
    },
  },
  methods: {
    sendMsg() {
      this.$refs.dialogImageCode.open();
    },
    // 发送验证码
    sendCheckCode(val) {
      let phoneNum = this.form.newPhone;
      if (!this.form.newPhone) {
        this.$message.error("请输入正确的手机号");
        return;
      } else {
        this.$axios
            .post(
                "/api/app-jycy-phoneverificationcode/verification", {
                  phone: phoneNum,
                  uuid: val.uuid,
                  code: val.code
                }
            )
            .then((res) => {
              if (res.data.success) {
                this.$message.success({
                  message: "验证码已经发送至您的手机，请注意接收",
                });
                this.waitTime = 180;
                this.timer();
              } else {
                this.getCodeBtnDisable = false;
                this.codeBtnWord = '获取验证码';
                this.$message.error({
                  message: res.data.msg || "发送失败",
                });
              }
            })
        this.$refs.dialogImageCode.close();
      }
    },
    //倒计时
    timer() {
      if (this.waitTime > 0) {
        this.waitTime--;
        this.codeBtnWord = this.waitTime + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.waitTime = 0;
        this.codeBtnWord = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    //确认修改手机号
    modifyMobile() {
      if (!this.form.newPhone) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.form.code) {
        this.$message.error("请输入收到的验证码");
        return;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = {
            iphone: this.form.newPhone,
            code: this.form.code,
          };
          let res = await this.$api.modifyTel(params);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.getUserInfo();
            this.form = {};
          }else {
             this.$message.error(res.data.msg);
          }
          console.log("修改手机号", res);
        }
      });
    },
    // 查询当前手机号
    getUserInfo() {
      let that = this;
      that.$api.getTel().then((res) => {
        console.log("获取手机号", res);
        if (!res.data.success) {
          this.iphone = "暂未绑定";
        } else {
          if (res.data.msg == null || res.data.msg == "") {
            this.iphone = "暂未绑定";
          } else {
            console.log(res);
            this.iphone = res.data.msg;
          }
        }
      });
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";
.el_slider__runway {
  text-align: center;
  line-height: 42px;
}
.sendcode {
  // margin-left: -29px;
  font-size: 14px;
}
/deep/ .el-input-group__append {
  background: #ffffff;
  color: #00924c;
  // border: 1px solid #00924c;
}
</style>
